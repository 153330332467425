import './styles/transaction-linetime.css'
import { useContext, useEffect, useRef, useState } from 'react';
import NotFoundBox from './NotFoundBox';
import { CheckIcon, CloseIcon, CopyIcon, InfoIcon, KevinNotFoundImg, PayIcon } from '../assets/images';
import Global from '../objects/Global';
import Contract from '../objects/Contract';
import Button from './Button';
import PropDisplay from './PropDisplay';
import Transaction from '../objects/Transaction';
import UserCard from './UserCard';
import { globalContext } from '../context/GlobalContext';
import ErrHandler from '../objects/ErrHandler';
import Hintbox from './Hintbox';

/**
 * @typedef {Object} TransactionLabelObject
 * @property {number} amount The amount for the transaction.
 * @property {*} current An object used for React.LegacyRef.
 * @property {Transaction} tr The transaction object.
 * @property {number} limitDate The limit date.
 * @property {number} period The zero-based period number (term value).
 */

/** Renders a TransactionsLineTime compo
 * @param {Object} props
 * @param {import('../objects/Contract').default} props.contract The contract attached. Its agreement
 * attribute must be defined.
 * @param {(t: TransactionLabelObject) => void} props.onPay
 * A callback function that will be trigger when 'Pay' or 'Mark as payed' button is pushed.
 * @param {'lessee'|'owner'} [props.role] The current session's role. If undefined, current session
 * is inactive or is not the owner nor lessee.
 * @param {number} props.today The current day in millis.
 * @param {Transaction} [props.transactions] An array of transactions.
 * All of them will be rendered. If not ordered, transactions will be ordered by date (asc).
 * If undefined, whole compo will be rendered on wait state
 */
const TransactionLineTime = props => {
  // *** useContext ***
  const { currSession, pushAlertMessage, timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const today = useRef(props.today);
  // *** useState ***
  const [transactions, setTransactions] = useState(/** @type {TransactionLabelObject[]} */(undefined));
  const [currTr, setCurrTr] = useState(/** @type {TransactionLabelObject} */(undefined));

  const copyToClipboard = input => {
    Global.copyToClipboard(input)
      .then(() => pushAlertMessage({ message: 'Copiado al portapapeles', type: 'complete' }))
      .catch(err => pushAlertMessage({ message: ErrHandler.parseError(err), type: 'error' }));
  }

  const disablePayButton = () => {
    if (props.role !== 'owner') return true;

    const currTrIdx = transactions.findIndex(t => t === currTr);
    const statusClass = Contract.statusToClass(props.contract, today.current);
    const invalidStatus = statusClass !== 'warning' && statusClass !== 'delayed';

    return currTrIdx === -1
      || (currTrIdx > 0 && (transactions[currTrIdx - 1].tr === undefined || invalidStatus))
      || (currTrIdx === 0 && invalidStatus);
  }

  /** @type {React.MouseEventHandler<HTMLSpanElement>} */
  const dotClickHandler = e => {
    setCurrTr(transactions.find(t => t.current === e.target));
  }

  const getCurrentTransactionPayType = () => {
    switch (currTr.tr.getPayType()) {
      case Transaction.PAY_TYPE_CASH: return 'Efectivo.';
      case Transaction.PAY_TYPE_INAPP: return 'Servicio de Racchome.';
      case Transaction.PAY_TYPE_TRANSFER: return 'Transferencia bancaria.';
      case Transaction.PAY_TYPE_OTHER: return 'Otro';
      default: return 'Desconocido';
    }
  }

  const getCurrentTransactionRef = () => {
    const type = currTr.tr.getPayType();

    if (type === Transaction.PAY_TYPE_CASH) return 'N/A';
    else return `${currTr.tr.getId()}` || 'N/E';
  }

  const getTransactionDate = () => {
    const date = Global.parseDateWithTimeUTC(currTr.tr.getCreationDate(), timezoneOffset);

    return date.substring(date.length - 7, date.length) === '(00:00)'
      ? date.substring(0, date.length - 7)
      : date;
  }

  const renderLine = () => {
    if (transactions === undefined || transactions.length === 0)
      return (<div className="linetime" />);

    /** @param {number} i */
    const getDotClass = i => {
      let clss = 'dot';

      if (i === 0) clss += ' first';

      if (transactions[i]?.tr !== undefined) {
        clss += transactions[i].tr.getCreationDate() <= transactions[i].limitDate + Global.DAY_TO_MILLI
          ? ' complete'
          : ' warning';
      } else if (transactions[i]?.limitDate < today.current)
        clss += ' error';

      if (transactions[i] === currTr) clss += ' selected';

      return clss;
    }

    /** @param {number} i */
    const getWidth = i => {
      const startDate = props.contract.getAgreement().getStartDate();
      const isValid = i >= 0 && i <= transactions.length
        && (today.current >= startDate || transactions[i].limitDate >= today.current)
        // Previous date.
        && (transactions.at(i - 1)?.limitDate <= today.current);

      if (!isValid) return 0;

      const lN = (i !== transactions.length
        ? transactions[i].limitDate
        : props.contract.getAgreement().getEndDate()
      ) - startDate;
      const tN = today.current - startDate;
      const fact = tN / (lN === 0 ? 1 : lN);

      return fact >= 1 ? 100 : Math.floor(fact * 100);
    }

    const dots = [];
    const lines = [];
    const last = transactions.length - 1;

    for (let i = 0; i < last; i++) {
      dots.push(<span className={getDotClass(i)}
        key={`d-${i}`}
        ref={transactions[i]}
        title='Haz clic para expandir.' />);
      lines.push(<div className={`fragment${i === 0 ? ' first' : i === last ? ' last' : ''}`}
        key={`f-${i}`} >
        <span className="process" style={{ 'width': `${getWidth(i + 1)}%` }} />
      </div>);
    }

    dots.push(<span className="dot final" key='d-final'>
      <img src={CheckIcon} alt="check" />
    </span>);

    return (<div className="linetime">
      <span className="curtain" />
      <div className="line">{lines}</div>
      <div className="dots" onClick={dotClickHandler}>{dots}</div>
    </div>);
  }

  useEffect(() => {
    const getMode = () => {
      switch (termMethod) {
        case Contract.TERM_METHOD_DAY: return 1;
        case Contract.TERM_METHOD_MONTH: return 2;
        default: return 3;
      }
    }

    setCurrTr();

    /** @type {TransactionLabelObject[]} */
    const auxArr = [];
    const chargeType = props.contract.getChargeType();
    const term = props.contract.getTerm();
    const termMethod = props.contract.getTermMethod();

    for (let i = 0; i < term; i++) {
      const prevDate = i > 0
        ? auxArr[i - 1].limitDate
        : props.contract.getAgreement().getStartDate();

      auxArr.push({
        amount: chargeType === Contract.CHARGE_CUSTOM
          ? props.contract.getPayAmount() + (props.contract.getCharge() || 0)
          : props.contract.getPayAmount() + (i === 0 && chargeType === Contract.CHARGE_SAME_AS_PAY
            ? props.contract.getPayAmount() : 0
          ),
        current: {},
        limitDate: Global.getNextDate(prevDate, { mode: getMode(), times: i === 0 ? 0 : 1 }).nextMill,
        period: i,
        tr: props.transactions?.at(i)
      });
    }

    setTransactions(auxArr);
  }, [props.contract, props.transactions]);

  return (<div className={`transaction-linetime${props.transactions === undefined ? ' wait' : ''}`}>
    {transactions === undefined && <span className="wait-curtain" />}
    {transactions !== undefined && transactions.length === 0 && <NotFoundBox img={KevinNotFoundImg} message='No hay transacciones' />}
    {transactions !== undefined && transactions.length > 0 && <div className="line-container">
      {renderLine()}
      <div className="transaction-container">
        {currTr === undefined && <div className="empty-transaction">
          Cada círculo representa un corte en el contrato. Haz clic sobre
          uno de ellos para ver más información.
        </div>}
        {currTr !== undefined && <div className="transaction">
          <div className="top-bar">
            <h5 className="highlight">Periodo {currTr.period + 1}</h5>
            <Button borderless
              empty
              icon={CloseIcon}
              onClick={() => setCurrTr()}
              reduced
              rounded
              stopPropagation
              typeRender='error' />
          </div>
          <div className="info-container">
            {currTr.tr === undefined && <div className="transaction-pending">
              <PropDisplay header='Fecha de corte'
                property={Global.parseDateUTC(currTr.limitDate, timezoneOffset)} />
              <PropDisplay header='Monto'
                property={`$ ${Global.formatNumber(currTr.amount)} MXN`} />
              {props.role !== undefined && <div className="flex-box">
                <div className="child auto-width m5">
                  <Button disabled={disablePayButton()}
                    empty
                    icon={PayIcon}
                    onClick={() => props.onPay(currTr)}
                    reduced
                    rounded
                    value={props.role === 'lessee' ? 'Pagar' : 'Marcar como pagado'} /></div>
                {((props.role === 'owner' && Contract.statusToClass(props.contract, today.current) === 'active')
                  || props.role === 'lessee') && <div className="child m5">
                    <Hintbox icon={InfoIcon}
                      message={props.role === 'lessee' ? 'Pronto podrás pagar a través de nuestra página.'
                        + ' Por ahora, realiza el pago mediante otro medio y avisa a tu arrendatario.'
                        : 'Podrás registrar el pago manualmente cuando esté en pronto corte.'} />
                  </div>
                }
              </div>}
            </div>}
            {currTr.tr !== undefined && <div className="transaction-payed">
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Estado del pago'
                    property={currTr.tr.getCreationDate() <= currTr.limitDate
                      ? 'Pago puntual' : 'Pago tardío'} />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Monto'
                    property={`$ ${Global.formatNumber(currTr.tr.getAmount())} MXN`} />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Fecha de la operación'
                    property={getTransactionDate()} />
                </div>
                <div className="child m3 auto-width">
                  <Button borderless
                    empty
                    icon={CopyIcon}
                    onClick={() => copyToClipboard(Global.parseDateWithTimeUTC(currTr.tr.getCreationDate()))}
                    reduced
                    rounded />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='HASH de bloque'
                    property={currTr.tr.getBlockHash() || 'Pendiente...'} />
                </div>
                <div className="child m3 auto-width">
                  <Button borderless
                    disabled={!currTr.tr.getBlockHash()}
                    empty
                    icon={CopyIcon}
                    onClick={() => copyToClipboard(currTr.tr.getBlockHash())}
                    reduced
                    rounded />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Tipo de operación'
                    property={getCurrentTransactionPayType()} />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Folio o referencia'
                    property={getCurrentTransactionRef()} />
                </div>
                <div className="child m3 auto-width">
                  <Button borderless
                    disabled={currTr.tr.getPayType() !== Transaction.PAY_TYPE_CASH}
                    empty
                    icon={CopyIcon}
                    onClick={() => copyToClipboard(getCurrentTransactionRef())}
                    reduced
                    rounded />
                </div>
              </div>
              <div className="payer-box">
                <span className="payed">Pagante</span>
                <UserCard user={currTr.tr.getPayer()}
                  displayName={currSession.username === currTr.tr.getPayer().getUsername() && 'Tú'}
                  mini
                  rounded />
              </div>
            </div>}
          </div>
        </div>}
      </div>
    </div>}
  </div>);
}

export default TransactionLineTime;