import Global from '../objects/Global';
import UIRender from '../objects/UIRender';
import './styles/inputbar.css';
import React, { useEffect, useRef, useState } from 'react';

/** InputbarFilterObject typedef
 * @typedef {Object} InputbarFilterObject
 * @property {RegExp} regExp The regular expression to use as filter.
 * @property {string} [replace] The replace value that will be used to replace when
 * filter matches. Empty string is the default.
 */

/** InputbarPlaceholderObject typedef
 * @typedef {Object} InputbarPlaceholderObject
 * @property {string} [default] Default placeholder for the compo. 'Inputbar' is the default.
 * @property {string} [onIsValidFail] A placeholder to show when isValid fails (if defined).
 * Default will be shown if unset.
 * @property {string} [onMinLengthFail] A placeholder to show then input doesn't aquire
 * minimum length (if defined). Default will be shown if unset.
 */

/** Renders an Inputbar compo.
 * @param {Object} props The props object.
 * @param {string} [props.defaultValue] Specifies the default value.
 * @param {boolean} [props.disabled] Specified if compo is disabled. When disabled, compo
 * value cannot be changed.
 * @param {string} [props.className] Adds a custom class name.
 * @param {InputbarFilterObject[]} [props.filters] An array of FilterObjects that will filter the input.
 * @param {React.MutableRefObject<(val?: string) => void>} [props.forceChangeRef] An object or a ref.
 * A callback function will be assigned to its current value. Call current to force input value to
 * change (f.e. <ref_def.current(<new_value>)). If undefined, compo will be restored to its default
 * value. Doing this won't trigger any assigned callback function and will clear onError and
 * onComplete hooks.
 * @param {string} [props.forceChangeValue] Use this property to force the input value to change
 * from the parent compo (with a useState hook). Doing this won't trigger any assigned callback
 * function. This callback won't be called if input is undefined (to clear input, send an empty
 * string instead). This property is deprecated. This value is deprecated.
 * @param {*} [props.icon] An icon to be displayed aside placeholder.
 * @param {'search'|'text'|'email'|'tel'|'url'|'decimal'|'numeric'|'none'} props.inputMode
 * Digital keyboard input mode for input compo. 'text' is the default.
 * @param {(input: string) => boolean} [props.isValid] A callback function that will review the input.
 * If valid, true should be return. This callback won't be called if input is empty.
 * @param {number} [props.maxLength] Maximum length for the input.
 * @param {number} [props.minLength] Minimum length for the input. Cannot be greater than maxLength
 * (will inherit maximum length value).
 * @param {(input?: string) => string} [props.onBlur] A callback function that will be called when
 * compo loses focus. Callback function will receive current input value and must return a new
 * (or the same) value for the input. If values doesn't match or doesn't fullfill minLenght (if
 * defined), input value will be replaced by the new value and onChange callback function will be called
 * (will return undefined if minLenght is greater). onBlur returned value will not pass through isValid
 * callback nor filters.
 * @param {(input?: string) => void} props.onChange A callback function that will be called
 * when compo changes. If isValid is defined and returns false, or minLength is defined and input
 * doesn't aquire minimum length, the param of this callback function will be undefined. For date
 * inputs, it may return an empty string if no date is set.
 * @param {(input?: string) => void} [props.onEnter] A callback funciton that will be called when
 * Enter is pressed.
 * @param {InputbarPlaceholderObject} [props.placeholder] Placeholder for the compo. 'Inputbar' by default.
 * @param {React.MutableRefObject<HTMLDivElement>} [props.reference] A reference for the input compo.
 * @param {boolean} [props.requestFocus] When compo is rendered, it will request focus to the DOM.
 * @param {boolean} [props.required] Specifies if input is required (displays a red * symbol).
 * @param {boolean} [props.showResolve] If set to true and input is valid, compo will render to green
 * when lose focus.
 * @param {boolean} [props.stopPropagation] false by default. If set to true, Event.stopPropagation( )
 * will be called when onChange occurs.
 * @param {boolean} [props.textCenter] If true, input will be centered.
 * @param {'capitalize'|'lowercase'|'uppercase'} [props.textTransform] Transforms the input. This won't
 * have effect if type is set to 'password'.
 * @param {string} [props.title] Adds a title to the compo.
 * @param {'date'|'email'|'number'|'password'|'search'|'tel'|'text'|'url'} [props.type] Input type.
 * @param {number} [props.width] Adds a custom width in pixels. If undefined, 100% will be the default.
 * text is the default.
 */
const Inputbar = props => {
  // *** useRef ***
  const currInput = useRef(props.defaultValue);
  const defaultValue = useRef(props.defaultValue || '');
  const inputRef = useRef(/** @type {HTMLInputElement|HTMLSelectElement} */(undefined))
  // *** useState ***
  const [onComplete, setOnComplete] = useState(false);
  const [onError, setOnError] = useState(/** @type {string} */(undefined));

  const getClassName = () => {
    let className = 'inputbar'

    if (props.textCenter) className += ' text-center';
    if (props.textTransform) className += ` ${props.textTransform}`;
    if (props.className) className += ` ${props.className}`;

    if (onError) className += ' error';
    else if (onComplete) className += ' complete';

    return className;
  }

  const getType = () => {
    const valid = props.type === 'date'
      || props.type === 'email'
      || props.type === 'number'
      || props.type === 'password'
      || props.type === 'search'
      || props.type === 'tel'
      || props.type === 'text'
      || props.type === 'url';

    if (valid) return props.type;
    else return 'text';
  }

  const getDefaultPlaceholder = () => {
    return props.placeholder?.default || 'Inputbar';
  }

  const getOnMinLengthFailPlaceholder = () => {
    return props.placeholder?.onMinLengthFail ||
      `La entrada debe tener al menos ${props.minLength} caracter${props.minLength > 1 ? 'es' : ''}`
  }

  const getOnIsValidFailPlaceholder = () => {
    return props.placeholder?.onIsValidFail || `La entrada no es válida`;
  }

  /** @type {React.FocusEventHandler} */
  const inputHandleOnBlur = () => {
    if (props.onBlur) {
      const newInput = props.onBlur(currInput.current);

      if (newInput !== currInput.current) {
        currInput.current = newInput;
        inputRef.current.value = newInput;

        if (props.minLength > 0 && newInput && newInput.length < props.minLength) { // Min length fail.
          setOnError(getOnMinLengthFailPlaceholder());
          props.onChange(undefined);
        } else {
          props.onChange(newInput || undefined);
        }
      }
    }
  }

  /** @type {React.ChangeEventHandler<HTMLInputElement>} */
  const inputHandleOnChange = e => {
    e.preventDefault();

    if (props.stopPropagation) e.stopPropagation();

    if (props.disabled) { // Compo is disabled.
      e.target.value = currInput.current;
      return;
    }

    let input = e.target.value;
    currInput.current = input; // Set value to ref.

    if (input) {
      // Text transform.
      if (props.textTransform && props.type !== 'password') {
        switch (props.textTransform) {
          case 'capitalize': {
            currInput.current = Global.capitalize(input);
            break;
          } case 'lowercase': {
            currInput.current = input.toLowerCase();
            break;
          } case 'uppercase': {
            currInput.current = input.toUpperCase();
            break
          } default: { }
        }
      }

      // Filters the value.
      if (props.filters?.length > 0) {
        for (let i = 0; i < props.filters.length; i++) {
          const f = props.filters[i];

          if (f.regExp) {
            input = input.replace(f.regExp, f.replace || '');
            currInput.current = currInput.current.replace(f.regExp, f.replace || '');
          }
        }
      }

      // Max length review.
      if (props.maxLength >= 0 && input.length > props.maxLength) {
        input = input.substring(0, props.maxLength);
        currInput.current = currInput.current.substring(0, props.maxLength);
      }

      if (e.target.value !== input) // Set value to target.
        e.target.value = input;

      const minLengthRev = !props.minLength || currInput.current?.length >= props.minLength;
      const isValidRev = !props.isValid || props.isValid(currInput.current);

      if (!minLengthRev || !isValidRev) { // Min length review.
        input = undefined;
        setOnError(!minLengthRev ? getOnMinLengthFailPlaceholder() : getOnIsValidFailPlaceholder);

        if (props.showResolve) setOnComplete(false);
      } else {
        input = currInput.current;
        setOnError();

        if (props.showResolve) setOnComplete(true);
      }
    } else {
      setOnError();

      if (props.showResolve) setOnComplete(false);
    }

    props.onChange(input || undefined);
  }

  /** @type {React.KeyboardEventHandler<HTMLInputElement>} */
  const inputHandleOnKeyDown = e => {
    if (!props.disabled && e.key === 'Enter') {
      props.onEnter(currInput.current || undefined);
    }
  }

  useEffect(() => {
    if (props.requestFocus) UIRender.setFocus(inputRef.current)
  }, [props.requestFocus]);

  useEffect(() => {
    if (props.forceChangeValue !== undefined && props.forceChangeValue !== currInput.current) {
      currInput.current = props.forceChangeValue;
      inputRef.current.value = currInput.current;
      setOnError();
      setOnComplete(false);
    }
  }, [props.forceChangeValue]);

  useEffect(() => {
    if (typeof props.forceChangeRef === 'object') {
      props.forceChangeRef.current = value => {

        if (value !== currInput.current) {
          currInput.current = value !== undefined ? value : defaultValue.current;
          setOnError();
          setOnComplete(false);
        }

        if (inputRef.current && inputRef.current.value !== currInput.current)
          inputRef.current.value = currInput.current;
      }
    }
  }, [props.forceChangeRef]);

  useEffect(() => {
    if (props.reference) inputRef.current = props.reference.current;
  }, [props.reference]);

  useEffect(() => {
    if (props.width) inputRef.current.parentElement.style.width = `${props.width}px`;
  }, [props.width]);

  return (
    <div className="inputbar-container" disabled={props.disabled}>
      <input
        ref={props.reference || inputRef}
        disabled={props.disabled}
        type={getType()}
        className={getClassName()}
        defaultValue={defaultValue.current}
        inputMode={props.inputMode || 'text'}
        maxLength={props.maxLength}
        minLength={props.minLength > props.maxLength
          ? props.maxLength
          : props.minLength > 0
            ? props.minLength
            : undefined}
        onBlur={inputHandleOnBlur}
        onChange={inputHandleOnChange}
        onKeyDown={props.onEnter ? inputHandleOnKeyDown : undefined}
        placeholder=' '
        title={props.title} />
      <label>
        {props.required && <span className='required' />}
        {props.icon && <img src={props.icon} alt="inputbar-icon" />}
        {onError || getDefaultPlaceholder()}
      </label>
    </div>
  );
}

export default Inputbar;