/** CentroidObject typedef
 * @typedef {Object} CentroidObject
 * @property {PointObject[]} children The children points attached to this centroid.
 * @property {PointObject} currPoint The current point of centroid.
 * @property {number} id An id for the centroid.
 * @property {PointObject} [prevPoint] The previous position of centroid.
 * @property {boolean} relocated A flag that points to true when centroid has been
 * relocated with given children.
 */

/** PointObject typedef
 * @typedef {Object} PointObject
 * @property {number} [id] An id
 * @property {number} x The x value.
 * @property {number} y The y value.
 */

class KMeans {
  static get MIN_MOVEMENT() { return 0.00000001 }

  /** @param {KMeans} [object] */
  constructor(object) {
    const { deletedCentroidsCount, centroids } = object ?? {};

    this.setDeletedCentroidsCount(deletedCentroidsCount);
    this.setCentroids([...(centroids ?? [])]);
  }

  /** Cluster the given points to existent centroids. This will only cluster the given points to
   * the existent centroids. If called again, clustered points will be erased and new given points will
   * be used.
   * @param {PointObject[]} points
   * @throws An error when centroids or points are undefined or empty.
   */
  cluster(points) {
    if (!Array.isArray(this.centroids) || this.centroids.length === 0)
      throw new Error('No centroids in the array');
    else if (!Array.isArray(points) || points.length === 0)
      throw new Error('No points given for clustering');

    // Erasing centroids children.
    this.getCentroids().forEach(c => { c.children = []; c.relocated = false });
    // K-Means algorithm for-each point.
    points.forEach(p => {
      /** @type {CentroidObject} */
      let ultCentroid;
      /** @type {number} The K-Means operation var. */
      let ultOp;

      this.getCentroids().forEach(c => {
        const { x: cX, y: cY } = c.currPoint;
        const { x: pX, y: pY } = p;
        // Calculating distance between current centroid and point.
        const newOp = Math.sqrt(Math.pow(cX - pX, 2) + Math.pow(cY - pY, 2));

        if (!ultCentroid || newOp < ultOp) { // No first centroid yet or new centroid is closer.
          ultCentroid = c;
          ultOp = newOp;
        }
      });

      ultCentroid.children.push(p);
    });
  }

  /** Obtains the deleted centroids. */
  getDeletedCentroidsCount() {
    return this.deletedCentroidsCount;
  }

  /** Assings the deleted centroids count
   * @param {number} deletedCentroidsCount;
   */
  setDeletedCentroidsCount(deletedCentroidsCount) {
    this.deletedCentroidsCount = deletedCentroidsCount || 0;
  }

  /** Obtains the centroids. */
  getCentroids() {
    return this.centroids;
  }

  /** Assings the centroids.
   * @param {CentroidObject[]} centroids
   */
  setCentroids(centroids) {
    this.centroids = centroids;
  }

  /** Relocates the centroids
   * @param {boolean} [ignoreEmptCent] If true, centroids that has no children won't be removed.
   * @returns Retuns an array of the deleted centroids. It will be empty if no centroid were deleted
   * or ignoreEmptCent is true.
   */
  relocate(ignoreEmptCent) {
    const centroids = this.getCentroids();
    const deletedCentroids = [];

    if (centroids === undefined || centroids.length === 0)
      throw new Error('No centroids in the array');

    // Relocate centroids.
    for (let i = centroids.length - 1; i >= 0; i--) {
      if (!ignoreEmptCent && !Boolean(centroids[i].children?.length)) {
        // Centroid has no children and won't be ignored.
        deletedCentroids.push(centroids[i]);
        centroids.splice(i, 1);
        this.setDeletedCentroidsCount(this.getDeletedCentroidsCount() + 1);
      } else {
        let sumX = 0, sumY = 0;

        centroids[i].children.forEach(c => { // Childrens' points addition.
          sumX += c.x;
          sumY += c.y;
        });

        // New location for the centroid.
        sumX = sumX / centroids[i].children.length;
        sumY = sumY / centroids[i].children.length;
        // Setting previous point.
        centroids[i].prevPoint = { ...centroids[i].currPoint };
        centroids[i].currPoint = { x: sumX, y: sumY };
      }
    }

    return deletedCentroids;
  }
}

export default KMeans;